import axios from "axios";

// Correct BASE_URL definition
const BASE_URL = "https://app.gogeeper.com/api/v1";
// const BASE_URL = 'http://localhost:8080/api/v1';

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "content-type": "application/json",
  },
  withCredentials: true,
});
