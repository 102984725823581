import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./styles/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//ts-ignore
import { ChakraProvider } from "@chakra-ui/react";
import { store } from "./redux/store";
import { GlobalAuthProvider } from "./provider";
import { NewAuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <GlobalAuthProvider>
          <NewAuthProvider>
            <>
              <ToastContainer />
              <App />
            </>
          </NewAuthProvider>
        </GlobalAuthProvider>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
