import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface AuthContextType {
  auth: any; // Replace 'any' with a more specific type if you know the structure of 'auth'
  setAuth: React.Dispatch<React.SetStateAction<any>>; // Same here for 'any'
}

// Create a context with a default value of undefined, which will be set in the provider
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface AuthProviderProps {
  children: ReactNode;
}

export function NewAuthProvider({ children }: AuthProviderProps) {
  const [auth, setAuth] = useState<AuthContextType["auth"]>(null);
  useEffect(() => {
    console.log(auth, "auth");
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

// import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// interface AuthContextType {
//   auth: any; // Replace with a more specific type if you know the structure of 'auth'
//   setAuth: React.Dispatch<React.SetStateAction<any>>;
// }

// // Create a context with a default value of undefined, which will be set in the provider
// export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// interface AuthProviderProps {
//   children: ReactNode;
// }

// export function NewAuthProvider({ children }: AuthProviderProps) {
//   const [auth, setAuth] = useState<AuthContextType['auth']>(() => {
//     // Retrieve from sessionStorage if available
//     const storedAuth = sessionStorage.getItem('auth');
//     return storedAuth ? JSON.parse(storedAuth) : null;
//   });

//   useEffect(() => {
//     // Persist auth state to sessionStorage whenever it changes
//     if (auth) {
//       sessionStorage.setItem('auth', JSON.stringify(auth));
//     } else {
//       sessionStorage.removeItem('auth');
//     }
//   }, [auth]);

//   return (
//     <AuthContext.Provider value={{ auth, setAuth }}>
//       {children}
//     </AuthContext.Provider>
//   );
// }
