import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authActions } from "./auth.slice";
import { isValidToken, setSession } from "../../utils/jwt";
import { axiosPrivate as axiosInstance } from "../../react-query/services/axios";

// const BASE_URL = 'http://localhost:8080/api/v1';
const BASE_URL = "https://app.gogeeper.com/api/v1/api/v1";

export const INITIALIZE =
  (accessToken: string): any =>
  async (dispatch: Dispatch) => {
    try {
      if (accessToken && isValidToken(accessToken)) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        const response = await axiosInstance.get(`${BASE_URL}/account/me`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        // console.log(response)
        dispatch(
          authActions.initialize({
            isAuthenticated: true,
            user: response.data.data.user,
          })
        );
      } else {
        dispatch(authActions.initialize({ isAuthenticated: false }));
      }
    } catch (e) {
      dispatch(authActions.initialize({ isAuthenticated: false }));
    }
  };

export const LOG_OUT = (): any => (dispatch: Dispatch) => {
  dispatch(authActions.startLoading());
  try {
    dispatch(authActions.logout());
    window.location.href = "/";
  } catch (error: any | string) {
    dispatch(
      authActions.hasError(
        error ? error?.response?.data : "Something went wrong"
      )
    );
  }
};
